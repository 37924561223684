import type { NextPage } from "next";

import { Container } from "@components/Container";
import client from "@lib/apollo";
import { getPageComponent } from "@lib/pageBuilder";
import { PAGE_QUERY } from "@lib/queries/page";

import { Pages_Default_Entry } from "__types__/graphql";

const Home: NextPage<{ entry: Pages_Default_Entry }> = ({ entry }) => {
  return (
    <Container
      title={entry.seo?.title ?? "Home | Tradewinds"}
      className="relative overflow-x-hidden"
    >
      {entry.pageBuilder.map((comp) => {
        if (!comp) {
          return null;
        }
        const Component = getPageComponent(comp.typeHandle);
        return <Component key={comp.id} {...comp} />;
      })}
    </Container>
  );
};

export async function getStaticProps() {
  const { data } = await client.query<{ entry: Pages_Default_Entry }>({
    query: PAGE_QUERY,
    variables: {
      slug: "home",
    },
  });

  return {
    props: {
      entry: data?.entry ?? {},
    },
    revalidate: 10,
  };
}

export default Home;
